import React, {useEffect, useState, PropsWithChildren} from 'react'
import styled from '@emotion/styled'
import Layout from '../components/mainLayout'

interface props {
  style?: React.CSSProperties
  location: any
}

const unsubscribe: React.FC<PropsWithChildren<props>> = ({location}) => {
  const [status, setStatus] = useState<'loading' | 'error' | 'done'>('loading')
  const [message, setMessage] = useState<string>('')

  // console.log('location', location);
  const id: string = location.search.slice(4)

  useEffect(() => {
    if (!id) {
      fetch(`${process.env.GATSBY_API_URL}/subscribers/${id}`, {
        // fetch(`http://localhost:1337/subscribers/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status < 300) {
            setMessage('')
            setStatus('done')
          } else {
            setMessage('Unsubscribe failed, a server error has occured')
            setStatus('error')
          }
        })
        .catch((error) => {
          setMessage(`${error.status}`)
          setStatus('error')
        })
    } else {
      setMessage('Subscriber id not found')
      setStatus('error')
    }
  }, [])
  return (
    <Layout>
      <Wrapper data-css='unsubscribe'>
        {status === 'loading' && (
          <UnsubMessage>
            <h1>Loading...</h1>
          </UnsubMessage>
        )}

        {status === 'done' && (
          <UnsubMessage>
            <h1>Unsubscribed.</h1>
            <p>
              You have successfully unsubscribed from The Justice Library
              Newsletter.
            </p>
            <p>We're sorry to see you go!</p>
            <ResubMessage>
              Didn't mean to unsubscribe? Resubscribe below!
            </ResubMessage>
          </UnsubMessage>
        )}
        {status === 'error' && (
          <UnsubMessage>
            <h1>Error</h1>
            <p>{message}</p>
            <ResubMessage>
              Please contact us via email if issues persist.
            </ResubMessage>
          </UnsubMessage>
        )}
      </Wrapper>
    </Layout>
  )
}

export default unsubscribe

const Wrapper = styled.div`
  padding: 10rem 5rem;
`
const UnsubMessage = styled.div`
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1.25rem;
  }
`
const ResubMessage = styled.p`
  margin: 5rem 0;
`
